import api from "@/libs/axios";

export default {
  async create(payload) {
    const response = await api.post("/admin/social-game/dictionary", payload);
    return response || null;
  },

  async update(payload) {
    let response = await api.put("/admin/social-game/dictionary", payload);
    return response;
  },

  async delete(payload) {
    const response = await api.delete("/admin/social-game/dictionary", {
      data: payload
    });
    return response;
  },

  async getList(params) {
    let response = await api.get("/admin/social-game/dictionary", {
      params
    });
    return response.data.data;
  },

  async get(params) {
    let response = await api.get("/admin/social-game/dictionary/detail", {
      params
    });
    return response.data.data.document || null;
  },
  async import(payload) {
    let response = await api.post(
      "/admin/social-game/dictionary/import",
      payload
    );
    return response;
  },
  async replace(payload) {
    let response = await api.post(
      "/admin/social-game/dictionary/replace",
      payload
    );
    return response;
  }
};
